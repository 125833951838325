/** 
 * We do a lot of bag.loading = true and then bag.loading = false when 
 *    doing something related to server. 
 * We also handle errors by catching and displaying them. 
 * This service does all of that boilerplate by using the 
 *    provided bag / context and error handling policy. 
 */

/**
 * @typedef {{running?: boolean, runError?: string}} TaskState 
 * @typedef {"alert" | "set" | "suppress"} TaskErrorHandling 
 * @typedef {ReturnType<taskWrapService>} taskWrap
 */

angular
.module('vdsr')
.factory('taskWrap', taskWrapService);

taskWrapService.$inject = [];

function taskWrapService() {
   
   return taskWrap;

   /** 
    * Handles boilerplate around running async executions 
    * @example
    *    // will alert the error to user 
    *    // won't merge the result to the bag
    *    // will set bag.running = true and then bag.running false when done
    *    taskWrap("alert", false, bag, () => api.doStuff(arg1, arg2))
    * @template T
    * @param {TaskErrorHandling} errorHandling - specify how to handle errors and whether to merge the result to the bag
    * @param {boolean} mergeResult - whether to merge the result to the given bag/context
    * @param {TaskState} bag
    * @param {() => angular.IPromise<T>} taskFn
    * @param {(arg0:Error)=>void} [errorFn]
    */
   function taskWrap(errorHandling, mergeResult, bag, taskFn, errorFn) {
      bag.running = true;
      bag.runError = '';
      let prom = taskFn();
      prom = mergeResult ? prom.then(result => angular.extend(bag, result)) : prom;
      prom = errorHandling === "alert" ? prom.catch(errorFn) :
         errorHandling === "set" ? prom.catch(err => bag.runError = err.message) :
         errorHandling === "suppress" ? prom.catch(_ => {}) :
         prom;
      prom['finally'](() => bag.running = false);
      
   }
}
